import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { json } from "react-router-dom";
import { DropdownComponent, ProductCard } from "../../../components";


const ShipmentOrder = ({shipmentData, isFromHome}) => {
    const [filterValue, setFilterValue] = useState("");
    const [shippingList, setShippingList] = useState(null);
    const [listProduct, setListProduct] = useState(null);
    const handleFilterShippingTo = () => {
        axios.get("https://api.happycat-happydog.id/Api/api/auth-dunex")
        .then(res => {
            const getData = res.data.data
            const getAuth =  JSON.parse(getData)
            console.log(getAuth)
            var myHeaders = new Headers();
            myHeaders.append("Authorization", getAuth.token);

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(`https://api.happycat-happydog.id/Api/shipment-dunex/?id=1&customer=${filterValue}`, requestOptions)
  .then(response => response.text())
  .then(result => {
    const data = JSON.parse(result);
    const cleanData = JSON.parse(data.data)
    console.log(cleanData.data);
    setShippingList(cleanData.data);
})
  .catch(error => console.log('error', error));
        })
        .catch(err => console.log(err))
    }
    return(
        <div style={{padding: 20}} >
            
            <Form>
                <legend>Shipment Order Form</legend>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Customer Name</Form.Label>
        <Form.Control type="text" placeholder="Enter Customer Name" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
        <Form.Label>No Phone</Form.Label>
        <Form.Control type="number" placeholder="no hp" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
        <Form.Label>Shipment Date</Form.Label>
        <Form.Control type="text" placeholder="Tanggal Shipment" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea4">
        <Form.Label>Sales Order Number</Form.Label>
        <Form.Control type="text" placeholder="Sales order number" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
        <Form.Label>transporter</Form.Label>
        <Form.Control type="text" placeholder="transporter" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea6">
        <Form.Label>Total Quantity</Form.Label>
        <Form.Control type="number" placeholder="total quantity" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea7">
        <Form.Label>Total Weight</Form.Label>
        <Form.Control type="number" placeholder="total Weight" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea8" style={{textAlign:"center"}} >
      <Button > Tambahkan Product </Button>
      </Form.Group >      
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea9" >
        <Row>
            {listProduct === null ? null : 
            
            <Col >
      <ProductCard/>
            </Col>
            }
        </Row>
      </Form.Group >    
     <div style={{ display:"flex", justifyContent:"center", marginBottom:200}} >
    <DropdownComponent 
    value={filterValue} 
    onChange={(e) => setFilterValue(e.target.value)} 
    onClick={handleFilterShippingTo.bind(this)} 
    item={shippingList}
    />
     </div >
      <Button variant="primary" type="submit" onClick={isFromHome} >
        Submit {" "}
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
</svg>
      </Button>
    </Form>
        </div>
    )
}
export default ShipmentOrder