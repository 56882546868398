
import { Provider } from 'react-redux';
import './App.css';
import RouteConf from './sources/routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from './sources/utils/redux/store';

function App() {
  return(
<Provider store={store} >
    <RouteConf/>;
</Provider>
  ) 
}

export default App;
