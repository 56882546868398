import React, { useEffect } from "react";
import { Image, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { loading_component } from "../../assets";


const LoadingComponent = () => {
   
    return(
        <div style={{padding: 20, display:"flex", justifyContent:"center", height:"100vh", alignItems:"center"}} >
            <div>
                <Image src={loading_component} width="contain" fluid />
                <div style={{display:"flex"}} >

                <p className="fs-1" style={{color:"#495057"}} > Tunggu, lagi cari data buat kamu !!! </p>
                <Spinner variant="primary" />
                </div>
            </div>

        </div>
    )
}

export default LoadingComponent;