import React from "react";
import { Toast } from "react-bootstrap";


const ToastComponent = ({show, message}) => {
   return (
    <Toast 
    show={show} 
   delay={3000} autohide
   bg="danger"
   >
         
         <Toast.Body className="text-white">{message}</Toast.Body>
       </Toast>
   )
}
export default ToastComponent;