const { createStore } = require("redux");

const initialState = {
    ship_id : null,
    delete_id : null,
    zoho_token : null,
}

const reducer = (state = initialState, action) => {
    if(action.type === 'SET_SHIP_ID'){
        return {
            ...state,
            ship_id: action.value
        }
    }
    if(action.type === 'SET_DELETE_ID'){
        return {
            ...state,
            delete_id: action.value
        }
    }
    if(action.type === 'SET_ZOHO_TOKEN'){
        return {
            ...state,
            zoho_token: action.value
        }
    }
    return state
}

const store = createStore(reducer);
export default store;