import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Table,Pagination } from "react-bootstrap";
import { BadgeFilter, LoadingComponent } from "../../../components";
import ShipmentDetails from "./shipmentDetails";
import { useDispatch } from "react-redux";



const Home = ({auth}) => {

const [shipmentId, setShipmentId] = useState(null);
const dispatch = useDispatch();
const [isShipmentOrder, setIsShipmentOrder ] = useState(false);
const [isLoadingScreen, setIsLoadingScreen] = useState(false);
const [shipmentData, setShipmentData] = useState(null);
const [token, setToken] = useState(null);
const [isLoading, setIsLoading] = useState(false);
const [dataShipment, setDataShipment] = useState(null);
const [activePagination, setActivePagination] = useState(1);
const [dataFilter, setDataFilter] = useState(null);
const [filterValue, setFilterValue] = useState(null);
const [isInFilter, setIsInFilter] = useState(false);
useEffect(()=>{
    setToken(auth);
    getShippment();
},[]);

const getShippment = async() =>{
    
    if(token === null) {
        setToken(auth)
    }
    const getToken = localStorage.getItem('token');
    const auth_head =  auth ?? getToken;
    dispatch({type: 'SET_ZOHO_TOKEN', value: auth_head});
    setIsLoading(true);
    localStorage.setItem('auth', auth_head);
 var myHeaders = new Headers();
 myHeaders.append("Authorization", `Bearer ${auth_head}`);
 
 var requestOptions = {
   method: 'GET',
   headers: myHeaders,
   redirect: 'follow'
 };
 
 
 fetch(`https://api.happycat-happydog.id/Api/shipment/list/${activePagination}`, requestOptions)
   .then(response => response.text())
   .then(result => {
     const getData = JSON.parse(result)
     const clearData = JSON.parse(getData.message[0].body)
     if(clearData.code === 0){

         console.log(clearData);
         setDataShipment(clearData.shipmentorders);
         setIsLoading(false);
        }
    })
    .catch(error => console.log('error', error));
}
const handlePagination = (value) => {
     setActivePagination(value);
     getShippment();
   
}


let active = activePagination;
let items = [];
for(let number = 1; number <= 5; number++){
    items.push(
        <Pagination.Item key={number} active={number === active} onClick={() =>handlePagination(number) }>
            {number}
        </Pagination.Item>
    )
}

const handleFilterData = async() => {
    setIsInFilter(true);
    setIsLoading(true);
    const auth_head = auth === null ? token : auth ; 
    let items = [];
    let dataItemsList = [];
for(let number = 1; number <= 15; number++){
   await axios.get(`https://api.happycat-happydog.id/Api/shipment/list/${number}`, {headers: {Authorization : `Bearer ${auth_head}`}})
   .then(res => {
    const data = JSON.parse(res.data.message[0].body);
    items.push(data.shipmentorders);
    console.log(items);
   })
   .catch(err => {console.log(err)});
}
for(let filter = 0; filter <= 14; filter++){

    const filterResult = items[filter].filter(item => item.shipment_number == filterValue  );
    dataItemsList.push(filterResult);
    
}

console.log(dataItemsList);
setDataFilter(dataItemsList);
setIsLoading(false);

}
    
const handleClickShipment = async (e) => {
        setShipmentId(e);
        console.log(e);
        setIsLoadingScreen(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${auth}`);
        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
       await fetch(`https://api.happycat-happydog.id/Api/shipment/${e}`, requestOptions)
        .then(response => response.text())
        .then(result => {
            const response = JSON.parse(result);
            const cleanResult = JSON.parse(response.message[0].body);
            setShipmentData(cleanResult.shipmentorder)
            
            // console.log(cleanResult);

})
  .catch(error => console.log('error', error));
  setIsShipmentOrder(true);
  setIsLoadingScreen(false);

     }

     if (isShipmentOrder){
        return <ShipmentDetails  shipmentData={shipmentData} isFromHome={() => setIsShipmentOrder(false)} onCencel={() => setIsShipmentOrder(false)}/>
     }
     
    if(isLoading || isLoadingScreen){
        return <LoadingComponent/>
    }
    const handleBadgeFilter = () => {
        setDataFilter(null);
        setFilterValue(null);
        setIsInFilter(false);
    }
    return(
        <div style={{padding: 20}} >
            <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Cari Berdasarkan Shipment Order Number / Customer Name"
              className="me-2"
              aria-label="Search"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
            <Button variant="outline-success" onClick={() => handleFilterData()} >Search</Button>
          </Form>
          <div style={{marginTop: 20, display:"flex", justifyContent:"flex-end"}} >
            {isInFilter && <BadgeFilter name={filterValue} onClick={() => handleBadgeFilter()} />}
          </div>

          <div  style={{marginTop:20}}>
          <Table striped bordered hover>
      <thead>
        <tr>
          <th >Date</th>
          <th>Shipment Order</th>
          <th>Customer Name</th>
          <th>Order Number</th>
          <th>Packages</th>
          <th>Carrier</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
{ dataFilter === null ? dataShipment === null ? null : dataShipment.map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>{item.salesorder_number}</td>
    <td>{item.shipment_number}</td>
    <td>{item.carrier}</td>
    <td>{item.status}</td>
    <td>
      <Button variant="secondary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         show
      </Button>
    </td>
  </tr>
)) : dataFilter[0] != null ?
dataFilter[0].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[1] != null ?
dataFilter[1].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[2] != null ?
dataFilter[2].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[3] != null ?
dataFilter[3].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[4] != null ?
dataFilter[4].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[5] != null ?
dataFilter[5].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[6] != null ?
dataFilter[6].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[7] != null ?
dataFilter[7].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[8] != null ?
dataFilter[8].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[9] != null ?
dataFilter[9].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[10] != null ?
dataFilter[10].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[11] != null ?
dataFilter[11].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[12] != null ?
dataFilter[12].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[13] != null ?
dataFilter[13].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  dataFilter[14] != null ?
dataFilter[14].map((item, index) => (
    <tr key={index} >
    <td>{item.date}</td>
    <td>{item.shipment_number}</td>
    <td>{item.customer_name}</td>
    <td>
      <Button variant="primary" style={{marginLeft:20,marginRight:20}} onClick={() => handleClickShipment(item.shipment_id)}  >
         Buat Shipment Order
      </Button>
      <Button variant="success" >
         Lihat Details
      </Button>
    </td>
  </tr>
)) :  null
}
      </tbody>
    </Table>
    <Pagination>{items}</Pagination>
          </div>
            
        </div>
    )
}
export default Home;