import React from "react";
import { Route, Routes, useLocation, useParams} from "react-router-dom";
import { AuthPage, SplashScreen } from "../pages";
import DashboardPage from "../pages/dashboard";




const RouteConf = () => {
    const code = useLocation()
    const paths = new URLSearchParams(code.search);
    const codes = paths.get("code");
    return(
        <Routes>
            <Route path="/" element={<SplashScreen/>} />
            <Route path="/dashboard" element={<DashboardPage/>} />
            <Route path={`:${codes}`} element={<AuthPage/>} />
            <Route path="/authLoader/*" element={<AuthPage/>} />
        </Routes>
    )
}
export default RouteConf