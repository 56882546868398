import React from "react";
import { Col, Row } from "react-bootstrap";


const CustomerCard = ({name, billingAddress}) => {
    return(
        
            <div style={{width: 500, padding:10 , border: '3px solid black',  borderRadius:10}}>
                <Row>
                    <Col lg={4} >
                    <p>nama customer</p>
                    </Col>
                    <Col>
                    <p>: {""}{name}</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} >
                    <p>Billing Address</p>
                    </Col>
                    <Col>
                    <p>: {""}{billingAddress}</p>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} >
                    <p>Shipping Address</p>
                    </Col>
                    <Col>
                    <p>: {""}{billingAddress}</p>
                    </Col>
                </Row>
            </div>
        
    )
}
export default CustomerCard;