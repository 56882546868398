import React, {  useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SideBar} from "../../components";
import Home from "./section/home";
import SalesOrderList from "./section/salesOrderList";
import ShipmentOrder from "./section/shipmentOrder";
import ShippingList from "./section/shippingList";
import TrackingShipmentPage from "./section/trackingShipment";






const DashboardPage = () => {
    const auth = localStorage.getItem('token');
    const [pageType, setPageType] = useState("home");
    const [token, setToken] = useState(null);
  
    useEffect(() => {
        setToken(auth);
    }, [])
   
    
    
    const ButtonNavigate = ({type}) =>{
        if(type === "home"){
            return <Home auth={token === null ? auth : token}   />
        }
        if(type === "shippingList"){
            return <ShippingList/>
        }
        if(type === "shipmentOrder"){
            return <ShipmentOrder  />
        }
        if(type === "trackingShipment"){
            return <TrackingShipmentPage  />
        }
        if(type === "salesOrder"){
            return <SalesOrderList auth={token === null ? auth : token} />
        }
    }
    return(
        <div style={{backgroundColor:"darkgray", height:"100vh", display:"flex"}} >
            <div>
                <SideBar 
                onClickHome={() => setPageType("home")} 
                onClickShipment={() => setPageType("shipmentOrder")}
                onClickSalesOrder={() => setPageType("salesOrder")}
                onClickTrackShip={() => setPageType("trackingShipment")}
                />
            </div>

                <div style={{width:"85%", 
                backgroundColor:"white", 
                height:"97vh", 
                left:"27vh", 
                position:"absolute",
                margin:10,
                borderRadius:15,
                overflowY:"scroll"
                
                }} >

                    <ButtonNavigate type={pageType} />

                </div>
            
        </div>
    )
}
export default DashboardPage;