import React from "react";
import { Card, Row, Col } from "react-bootstrap";



const ProductCard = ({sku, productName, expiredDate, quantity, warehouses}) => {
    return(
        <div>
    <Card style={{width:"75vh"}} className="mb-2" >
    <Card.Header>
        <Row>
            <Col>{productName}</Col>
            <Col lg="1" style={{textAlign:"end"}} >
                <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
                </div>
            </Col>
        </Row>
    </Card.Header>
      <Card.Body>
        <Row>
            <Col lg="3">
                 <Card.Text>
                     SKU
                 </Card.Text>
            </Col>
            <Col>
                 <Card.Text>
                    : {sku}
                 </Card.Text>
            </Col>
        </Row>
        <Row>
            <Col lg="3">
                 <Card.Text>
                     expired Date:
                 </Card.Text>
            </Col>
            <Col>
                 <Card.Text>
                    : {expiredDate}
                 </Card.Text>
            </Col>
        </Row>
        <Row>
            <Col lg="3">
                 <Card.Text>
                 quantity
                 </Card.Text>
            </Col>
            <Col>
                 <Card.Text>
                    : {quantity}
                 </Card.Text>
            </Col>
        </Row>
        <Row>
            <Col lg="3">
                 <Card.Text>
                 warehouses
                 </Card.Text>
            </Col>
            <Col>
                 <Card.Text>
                    : {warehouses}
                 </Card.Text>
            </Col>
        </Row>
       
      </Card.Body>
    </Card>
        </div>
    )
}
export default ProductCard;