

export const APIEndpoint = () => {
   const developmentUri = 'https://api.happycat-happydog.id/Api/';
   const currentApiEndPoint = developmentUri;
   
    
    return {
        auth_dunex : currentApiEndPoint + "auth-dunex/devAuth",
        shipment_order_dunex : currentApiEndPoint + "shipment-dunex/shipment-order",
        edit_shipment_order_dunex : currentApiEndPoint + "shipment-dunex/revise-shipment-order",
        tracking_shipment_order_dunex : currentApiEndPoint + "shipment-dunex/track-shipment",// tambahkan parameter order id
        auth_zoho : currentApiEndPoint + "auth",
        create_shipment_locale : currentApiEndPoint + "locale-shipment"

    }

}