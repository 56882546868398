import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Container, Table , Form} from "react-bootstrap";
import { APIEndpoint } from "../../../utils/api-text";
import { BadgeFilter, LoadingComponent } from "../../../components";
import TrackingShipmentEdit from "./trackingShipmentEdit";
import { useDispatch } from "react-redux";
import TrackingShipmentShow from "./trackingShipmentShow";


const TrackingShipmentPage = () => {
    const [listData, setListData] = useState(null);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditShipment, setIsEditShipment] = useState(false);
    const [isShowShipment, setIsShowShipment] = useState(false);
    const [isInFilter, setIsInFilter] = useState(false);
    const [ filterValue, setFilterValue] = useState('');
    useEffect(()=>{
        getShipmentList();
    },[])
    const getShipmentList = () => {
        setIsLoading(true);
       const {create_shipment_locale} = APIEndpoint();
        axios.get(create_shipment_locale)
        .then(res => {
            console.log(res.data.data);
            setListData(res.data.data);
            setIsLoading(false);
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false);
        })
    }
    if(isLoading){
        return <LoadingComponent/>
    }
    if(isEditShipment){
        return <TrackingShipmentEdit onCancel={() => setIsEditShipment(false)} />
    }
    if(isShowShipment){
        return <TrackingShipmentShow onCancel={() => setIsShowShipment(false)} />
    }
    const handleEditShipment = (ship_id) => {

        dispatch({type: 'SET_SHIP_ID', value: ship_id });
        setIsEditShipment(true);
    }
    const handleShowShipment = (id) => {
        dispatch({type: 'SET_SHIP_ID', value: id });
        setIsShowShipment(true);
    }
    const filterData = listData === null ? null : listData.filter(item => item.order_id === filterValue);
    const handleFilter = () => {      
       setIsInFilter(true);
    }
    const handleBadgeFilter = () => {
        setFilterValue('');
        setIsInFilter(false);
    }
    return(
        <div  style={{padding:20}}>
            <Container>
                <p className="fs-5" > 
                <Badge bg="secondary" > Tracking Shipment</Badge>
                 </p>
                 <Form className="d-flex" style={{marginBottom: 20}} >
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                  />
                  <Button onClick={() => handleFilter()} variant="outline-success">Search</Button>
                </Form>
                    {isInFilter && <BadgeFilter name={filterValue} onClick={() => handleBadgeFilter()} />}
                 <Table striped bordered hover style={{marginTop: 20}} >
                   
      <thead>
        <tr>
          <th>No</th>
          <th>Order ID</th>
          <th>Customer Name</th>
          <th>Carrier</th>
          <th>Status Pengiriman Data</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        { isInFilter === true ? filterData.map((item, index) => (
            <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.order_id}</td>
          <td>{item.customer_name}</td>
          <td>{item.transporter}</td>
          <td>
            <p className="fs-5" >
                <Badge bg="success" >Success</Badge>
            </p>
          </td>
          <td>
            <Button variant="secondary" style={{marginRight: 20, marginLeft:20}} onClick={() => handleShowShipment(item.order_id)} >
                show
            </Button>
            <Button variant="primary" onClick={() => handleEditShipment(item.id)} >
                edit
            </Button>
          </td>
        </tr>
        )) : listData ? listData.map((item, index) => (

            <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.order_id}</td>
          <td>{item.customer_name}</td>
          <td>{item.transporter}</td>
          <td>
            <p className="fs-5" >
                <Badge bg="success" >Success</Badge>
            </p>
          </td>
          <td>
            <Button variant="secondary" style={{marginRight: 20, marginLeft:20}} onClick={() => handleShowShipment(item.order_id)} >
                show
            </Button>
            <Button variant="primary" onClick={() => handleEditShipment(item.id)} >
                edit
            </Button>
          </td>
        </tr>
            )) : null}
       
      </tbody>
    </Table>
            </Container>
        </div>
    )
}

export default TrackingShipmentPage;