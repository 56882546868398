import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { APIEndpoint } from "../../../utils/api-text";
import { ProductCard } from "../../../components";



const TrackingShipmentEdit = ({onCancel}) => {
    const stateGlobal = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [detailShipment, setDetalShipment] = useState(
        {
            customer_name: '',
            order_id: '',
            no_phone:'',
            sales_order: '',
            shipment_date: '',
            shipping_address: '',
            total_qty: '',
            total_weight: '',
            transporter: '',
            shipping_note: '',
            salesorder_id: '',

        }
    )
    const [listProduct, setListProduct] = useState([]);
    const Shipid = stateGlobal.ship_id;
    useEffect(() => {
        getDetailsData();
    },[])
    const getProduct = ({salesorder_id}) => {
        setLoading(true);
        let token = localStorage.getItem('token');
        let auths = localStorage.getItem('auth');
        let getAuth = token === undefined ? auths : token
        axios.get(`https://api.happycat-happydog.id/Api/shipment/salesorders/${salesorder_id}`, {headers: {Authorization: `Bearer ${getAuth}`}})
        .then(res => {
            console.log("ini tu product edit shipment",res);
            const data = JSON.parse(res.data.message[0].body);
            setListProduct(data.salesorder.line_items);
            setLoading(false);
        })
        .catch(err => {
            console.log(err)
            setLoading(false);
        })
    }
    const getDetailsData = () => {
        const {create_shipment_locale} = APIEndpoint();
        axios.get(`${create_shipment_locale}/${Shipid}`)
        .then(res => {
            console.log(res);
            setDetalShipment({
                customer_name: res.data.customer_name,
                order_id: res.data.order_id,
                sales_order: res.data.sales_order,
                shipment_date: res.data.shipment_date,
                shipping_address: res.data.shipping_address,
                total_qty: res.data.total_qty,
                total_weight: res.data.total_weight,
                transporter: res.data.transporter,
                shipping_note: res.data.shipping_note,
                salesorder_id: res.data.salesorder_id,
                no_phone: res.data.no_hp

            });
            getProduct({salesorder_id: res.data.salesorder_id});
        })
        .catch(err => {
            console.log(err)
        })
    }
    const submitEditShipment = () => {
        setButtonLoading(true);
        const data = {
            order_id: `${detailShipment.order_id}`,
              customer_name: `${detailShipment.customer_name}`,
              no_hp: `${detailShipment.no_phone}`,
              shipping_address: `${detailShipment.shipping_address}`,
              shipment_date: `${detailShipment.shipment_date}`,
              sales_order : `${detailShipment.salesorder_number}`,
              transporter: `${detailShipment.trasporter}`,
              total_qty: `${detailShipment.total_quantity}`,
              total_weight: `${detailShipment.total_weight}`,
              shipping_note: `${detailShipment.shipping_note}`,
              salesorder_id: `${detailShipment.salesorder_id}`,
              products : listProduct.map(item => {return {
                sku : `${item.sku}`,
                product_name: `${item.description}`,
                expired_date: item.item_custom_fields.length === 2 ? item.item_custom_fields[1].value : "",
                warehouse_allocation : FilterWarehouses({warehouse_id: item.warehouse_id}),
                quantity: `${item.quantity}`,
                stock_unit: `${item.unit}`,
              }})
        }
        const {edit_shipment_order_dunex, auth_dunex} = APIEndpoint();
        axios.get(auth_dunex)
        .then(res => {
            
            axios.post(edit_shipment_order_dunex, data, {headers: {Authorization: `Bearer ${res.data.data.token}`}} )
            .then(res => {
                console.log(res);

            })
        })
        .catch(err => {
            console.log(err)
        })
    }
    function FilterWarehouses ({warehouse_id}) {
        if(warehouse_id === '1956474000000343009'){
          return 'Warehouse Jakarta - Good Bags'
        }
        if(warehouse_id === '1956474000000345001'){
          return 'Warehouse Jakarta - Broken Bags'
        }
        if(warehouse_id === '1956474000069612759'){
          return 'Warehouse Surabaya - Good Bags'
        }
        if(warehouse_id === '1956474000069626867'){
          return 'Warehouse Surabaya - Broken Bags'
        }
        if(warehouse_id === '1956474000145162569'){
          return 'WH B2C (Teguh)'
        }
       }
    // console.log("ini id shipment :" ,id)
    return(
        <div style={{padding:20}} >
            
                <p className="fs-3" >

                <Badge bg="secondary" >
                    Edit Shipment Order Dunex
                </Badge>
                </p>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>Customer Name</Form.Label>
        <Form.Control 
        value={detailShipment.customer_name} 
        onChange={(e) => setDetalShipment({...detailShipment, customer_name: e.target.value})} 
        type="text" 
        placeholder="Customer Name" 
        />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>Sales Order</Form.Label>
        <Form.Control 
        type="text" 
        placeholder="Sales Order" 
        value={detailShipment.sales_order}
        onChange={(e) => setDetalShipment({...detailShipment, sales_order: e.target.value})}
        />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>shipment Date</Form.Label>
        <Form.Control 
        type="date" 
        placeholder="Shipment Date" 
        value={detailShipment.shipment_date}
        onChange={(e) => setDetalShipment({...detailShipment, shipment_date: e.target.value})}
        />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>No Phone</Form.Label>
        <Form.Control 
        type="number" 
        placeholder="No Phone" 
        value={detailShipment.no_phone}
        onChange={(e) => setDetalShipment({...detailShipment, no_phone: e.target.value})}
        />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>Shipping Address</Form.Label>
        <Form.Control 
        type="text" 
        placeholder="Shipping Address" 
        value={detailShipment.shipping_address}
        onChange={(e) => setDetalShipment({...detailShipment, shipping_address: e.target.value})}
        />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>Total Qty</Form.Label>
        <Form.Control 
        type="text" 
        placeholder="Total Qty" 
        value={detailShipment.total_qty}
        onChange={(e) => setDetalShipment({...detailShipment, total_qty: e.target.value})}
        />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>Total Weight</Form.Label>
        <Form.Control 
        type="text" 
        placeholder="Total Weight"
        value={detailShipment.total_weight}
        onChange={(e) => setDetalShipment({...detailShipment, total_weight: e.target.value})}
        />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>Transporter</Form.Label>
        <Form.Control 
        type="text" 
        placeholder="Transporter" 
        value={detailShipment.transporter}
        onChange={(e) => setDetalShipment({...detailShipment, transporter: e.target.value})}
        />
      </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Form.Label>Shipping Note</Form.Label>
        <Form.Control 
        type="text" 
        placeholder="Shipping Note" 
        value={detailShipment.shipping_note}
        onChange={(e) => setDetalShipment({...detailShipment, shipping_note: e.target.value})}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea9" >
        <div style={{display:"flex", justifyContent:"center", marginTop:30}} >
      {loading === true ? <Spinner variant="primary" /> : 
        <Row>
            
           { listProduct ? listProduct.map((item, index) => (       
               <Col >
            <ProductCard 
            key={index}
            productName={item.description} 
            sku={item.sku}  
            quantity={item.quantity} 
            expiredDate={item.item_custom_fields.length === 2 ? item.item_custom_fields[0].value : ""}
            warehouses={FilterWarehouses({warehouse_id:item.warehouse_id})}
            />
            </Col>
            )) : null
            }
           
           
        
        </Row>
        }
        </div>
      </Form.Group >
        <Form.Group className="mb-3" controlId="formBasicCustomerName">
        <Button variant="danger" onClick={onCancel} style={{marginRight:20}} >
            Cancel
        </Button>
        {buttonLoading ? <Button variant="success" > <Spinner size="sm" variant="warning" /> </Button>
        
    :
        <Button variant="success" disabled onClick={() => submitEditShipment()} >
            Save Edit
        </Button>
        }
      </Form.Group>
            
        </div>
    )
}
export default TrackingShipmentEdit;
