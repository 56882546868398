import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Row, Spinner } from "react-bootstrap";
import { AlertComponent, ProductCard, ToastComponent } from "../../../components";
import { APIEndpoint } from "../../../utils/api-text";
import { useDispatch, useSelector } from "react-redux";



const ShipmentDetails = ({shipmentData, isFromHome,onCencel}) => {
  const dispatch = useDispatch();
  const stateGlobal = useSelector(state => state);
    const [listProduct, setListProduct] = useState(null);
    const [shipmentList, setShipmentList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [totalWeights, setTotalWeights] = useState('');
    const [warehousesList, setWarehousesList] = useState(null);
    const [deleteId, setDeleteId] = useState('');
    const [detectedError, setDetectedError] = useState(false);
    const [detectedErrorType, setDetectedErororType] = useState({
      customer_name : false,
      no_phone: false,
      shipping_address: false,
      shipment_date: false,
      salesorder_number: false,
      trasporter: false,
      total_quantity: false,
      total_weight: false,
      shipping_note: false,
    });
    const [shipment,setShipment] = useState({
      customer_name : '',
      no_phone: '',
      shipping_address: '',
      shipment_date: '',
      salesorder_number: '',
      trasporter: '',
      total_quantity: '',
      total_weight: '',
      shipping_note: '',
    });
    
    useEffect(() => {
        
        getProductList();
        console.log("shipmentData : ", shipmentData);
       
    }, [])
    const validasiForm = () => {
      if(shipment.customer_name === '' || shipment.customer_name === undefined) {
        setDetectedError(true);
        setDetectedErororType({...detectedErrorType, customer_name : true});
        
        
      } 
      else if(shipment.no_phone === '' || shipment.no_phone === undefined){
        setDetectedError(true);
        setDetectedErororType({...detectedErrorType, no_phone : true});
        
        
      }
      else if(shipment.salesorder_number === '' || shipment.salesorder_number === undefined){
        setDetectedError(true);
        setDetectedErororType({...detectedErrorType, salesorder_number : true});

        
      }
      else if(shipment.shipment_date === '' || shipment.shipment_date === undefined){
        setDetectedError(true);
        setDetectedErororType({...detectedErrorType, shipment_date : true});
        
      }
      else if(shipment.shipping_address === '' || shipment.shipping_address === undefined){
        setDetectedError(true);
        setDetectedErororType({...detectedErrorType, shipping_address : true});
        
      }
      else if(shipment.total_quantity === '' || shipment.total_quantity === undefined){
        setDetectedError(true);
        setDetectedErororType({...detectedErrorType, total_quantity : true});
        
      }
      
    }
    const handleSubmitShipmentOrder = () => {
      validasiForm();
      setSubmitLoading(true);

      
        const {auth_dunex, shipment_order_dunex, create_shipment_locale} = APIEndpoint(); 
        const data =  {
          order_id: `${shipmentData.shipment_number}`,
          customer_name: `${shipment.customer_name}`,
          no_hp: `${shipment.no_phone}`,
          shipping_address: `${shipment.shipping_address}`,
          shipment_date: `${shipment.shipment_date}`,
          sales_order : `${shipment.salesorder_number}`,
          transporter: `${shipment.trasporter}`,
          total_qty: `${shipment.total_quantity}`,
          total_weight: `${totalWeights}`,
          shipping_note: `${shipment.shipping_note === undefined ? " " : shipment.shipping_note}`,
          products : listProduct.map(item => {return {
            sku : `${item.sku}`,
            product_name: `${item.description}`,
            expired_date: item.item_custom_fields.length == 2 ? item.item_custom_fields[0].value : "",
            warehouse_allocation : `${FilterWarehouses({warehouse_id: item.warehouse_id})}`,
            quantity: `${item.quantity}`,
            stock_unit: `${item.unit}`,
          }})
        } 
        axios.post(create_shipment_locale, data)
        .then(res => {
          
          setDeleteId(res.data.data);
          dispatch({type: 'SET_DELETE_ID', value: res.data.data});
          const deleteMark = res.data.data;
          console.log(res.data.data);
          // console.log(data);
          axios.get(auth_dunex)
          .then(res => {
            setSubmitLoading(false);
            console.log(res.data.data.token);  
            axios.post(shipment_order_dunex, data, {headers: {Authorization : res.data.data.token}})
            .then(res => {
              console.log(data);
              if(!res.data.data.error){
                setAlertType('success');
                setAlertTitle('Shipemnt Order Success !');
                setAlertMessage('shipment order berhasil dikirim ke dunex !');
                setIsAlert(true);
                console.log(res);
              }else {
                console.log(res);
                axios.delete(`${create_shipment_locale}/${deleteMark}`)
                .then(res => {
                  console.log(res);
                })
                .catch(err => {
                  console.log(err);
                })
                setAlertType('error');
                setAlertTitle('Shipemnt Order Faild !');
                setAlertMessage('terjadi kesalahan dengan jumlah :' + " " + res.data.data.count_error);
                setIsAlert(true);
                setSubmitLoading(false)
                
              }
              
            })
            .catch(err => {
              axios.delete(`${create_shipment_locale}/${deleteMark}`)
              .then(res => {
                console.log(res);
              })
              .catch(err => {
                console.log(err);
              })
              setAlertType('error');
              setAlertTitle('Shipemnt Order Error !');
              setAlertMessage('shipment order gagal dikirim ke dunex !');
              console.log(err);
              setSubmitLoading(false)
            })
          })
          .catch(err => {
            console.log(err);
          })
        })
        .catch(err => {
          console.log(err);
          setAlertType('error');
          setAlertTitle('Shipemnt Order Error !');
          setAlertMessage('shipment order gagal dikirim ke dunex !');
          setSubmitLoading(false)
        });
      
      }
      
       
   
    const getProductList = async() => {
        setLoading(true);
        setShipmentList(shipmentData);
        setShipment({
          customer_name: `${shipmentData.customer_name}`,
          no_phone:shipmentData.shipping_address.phone, 
          shipping_address: shipmentData.shipping_address.attention + shipmentData.shipping_address.address + " " + shipmentData.shipping_address.phone,
          shipment_date: `${shipmentData.date}`,
          salesorder_number: shipmentData.salesorder_number,
          trasporter: shipmentData.custom_fields[0] === undefined ? " " :  shipmentData.custom_fields[0].value ,
          total_quantity:shipmentData.packages.length == 0 ? " " :  shipmentData.packages[0].package_quantity,
        })
        let token = stateGlobal.zoho_token;
        let auths = localStorage.getItem('auth');
        let getAuth = token === null ? auths : token
        let id = shipmentData.salesorder_id;
        await axios.get(`https://api.happycat-happydog.id/Api/shipment/salesorders/${id}`, {headers : {Authorization : `Bearer ${getAuth}`}})
        .then(res => {
            const data = JSON.parse(res.data.message[0].body);
            setListProduct(data.salesorder.line_items);
            console.log("data list items : " , res);
            setWarehousesList(data.salesorder.warehouses);
            setTotalWeights(data.salesorder.custom_fields[1] === undefined ? 'tidak ada data' : data.salesorder.custom_fields[1].value)
            setShipment({
              ...shipment.shipping_note,
              customer_name: shipmentList.customer_name, 
              no_phone :shipmentList.no_phone, salesorder_number:  shipmentList.salesorder_number, 
              shipment_date :shipmentData.shipment_date, 
              shipping_address :shipmentData.shipping_address.attention + shipmentData.shipping_address.address + " " + shipmentData.shipping_address.phone, 
              total_quantity : shipmentData.packages.length == 0 ? " " :  shipmentData.packages[0].package_quantity,
              trasporter : shipmentData.custom_fields[0] === undefined ? " " :  shipmentData.custom_fields[0].value
            })
          })
          .catch(err => console.log(err));
          setLoading(false);
        }

        function FilterWarehouses ({warehouse_id}) {
          if(warehouse_id === '1956474000000343009'){
            return "Warehouse Jakarta - Good Bags"
          }
          if(warehouse_id === '1956474000000345001'){
            return "Warehouse Jakarta - Broken Bags"
          }
          if(warehouse_id === '1956474000069612759'){
            return "Warehouse Surabaya - Good Bags"
          }
          if(warehouse_id === '1956474000069626867'){
            return "Warehouse Surabaya - Broken Bags"
          }
          if(warehouse_id === '1956474000145162569'){
            return "WH B2C (Teguh)"
          }
         }
    
    return(
        <div style={{padding: 20}} >
                <div style={{ display:"flex", justifyContent:"space-between"}} >
                <legend>Shipment Order Details Form</legend>
                <div onClick={onCencel} style={{cursor:"pointer"}} >
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
                </div>
                </div>
            

        {shipmentList === null ? null : 
                <Form>                    
       <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Customer Name</Form.Label>
        <Form.Control value={shipment.customer_name} onChange={(e) => setShipment({...shipment, customer_name: e.target.value})} type="text" placeholder="Enter Customer Name" />
      </Form.Group>
        

      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
        <Form.Label>No Phone</Form.Label>
        <Form.Control value={shipment.no_phone} onChange={(e) => setShipment({...shipment, no_phone: e.target.value})} type="text" placeholder="no hp" />
          <ToastComponent show={detectedErrorType.no_phone} message="tolong isi nomor phone"/>
      </Form.Group>
      <Row className="mb-3">
        <Form.Group style={{width:"50vh"}} controlId="formGridAttention">
          <Form.Label>Shipping Address</Form.Label>
          <Form.Control value={shipment.shipping_address}
         onChange={(e) => setShipment({...shipment, shipping_address: e.target.value})}
          as="textarea" 
          rows={10} placeholder="shipping address" />
        </Form.Group>
        </Row>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
        <Form.Label>Shipment Date</Form.Label>
        <Form.Control value={shipment.shipment_date} onChange={(e) => setShipment({...shipment, shipment_date: e.target.value})}  type="text" placeholder="Tanggal Shipment" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea4">
        <Form.Label>Sales Order Number</Form.Label>
        <Form.Control value={shipment.salesorder_number} onChange={(e) => setShipment({...shipment, salesorder_number: e.target.value})} type="text" placeholder="Sales order number" />
        <ToastComponent show={detectedErrorType.salesorder_number} message="tolong isi nomor salesorder"/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
        <Form.Label>transporter</Form.Label>
        <Form.Control value={shipment.trasporter} onChange={(e) => setShipment({...shipment, trasporter: e.target.value})} type="text" placeholder="transporter" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea6">
        <Form.Label>Total Quantity</Form.Label>
        <Form.Control value={shipment.total_quantity} onChange={(e) => setShipment({...shipment, total_quantity: e.target.value})} type="number" placeholder="total quantity" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea7">
        <Form.Label>Total Weight</Form.Label>
        <Form.Control  value={totalWeights} onChange={(e) =>setTotalWeights(e.target.value)} type="text" placeholder="total Weight" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea8">
        <Form.Label>Shipping Note</Form.Label>
        <Form.Control  value={shipment.shipping_note} onChange={(e) =>setShipment({...shipment, shipping_note: e.target.value})} type="text" placeholder="Shipment Note" />
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea8" style={{textAlign:"center"}} >
      <Button > Tambahkan Product </Button>
      </Form.Group >       */}
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea9" >
        <div style={{display:"flex", justifyContent:"center", marginTop:30}} >
      {loading === true ? <Spinner variant="primary" /> : 
        <Row>
            
           { listProduct ? listProduct.map((item, index) => (       
               <Col key={index}>
            <ProductCard 
            productName={item.description} 
            sku={item.sku}  
            quantity={item.quantity} 
            expiredDate={item.item_custom_fields.length == 2 ? item.item_custom_fields[0].value : ""}
            warehouses={FilterWarehouses({warehouse_id:item.warehouse_id})}
            />
            </Col>
            )) : null
            }
           
           
        
        </Row>
        }
        </div>
      </Form.Group >    
 
     {isAlert ? null : 
     <Button onClick={isFromHome} variant="danger" style={{marginRight:20}} >Cancel</Button>
     }
      
        {isAlert === true ? <AlertComponent type={alertType} title={alertTitle} message={alertMessage} onClick={isFromHome} onClose={() => setIsAlert(false)}/> : <Button variant="primary"  onClick={() => handleSubmitShipmentOrder()} >
        Submit {" "}
        {submitLoading ? <Spinner variant="warning" size="sm" /> : 
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
  <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"/>
</svg>
        }
      </Button>}
        
      
    </Form>
    }

        </div>
    )
}
export default ShipmentDetails;