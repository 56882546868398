import React from "react";
import { Dropdown, Form, Button } from "react-bootstrap";


const DropdownComponent = ({name, item, onClick, value, onChange, onTakeValue}) => {

    return(
        <Dropdown >
        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:"75vh"}} >
          {name != null ? name : "pilih shipping to"}
        </Dropdown.Toggle>
  
        <Dropdown.Menu style={{width:"75vh", padding:10}} >
        <Form className="d-flex">
            <Form.Control
              type="text"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value={value}
              onChange={onChange}
            />
            <Button variant="outline-success" onClick={onClick} >Search</Button>
          </Form>
          {item === null ? null : item.map(items => (

          <Dropdown.Item onClick={onTakeValue}>{items.Customer}</Dropdown.Item>
          ))}
          {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
    )
}


export default DropdownComponent;