import React from "react";
import { Alert , Button} from "react-bootstrap";



const AlertComponent = ({ type, message, title, alertShow, onClick, onClose}) => {
    if(type === "success"){
        return (
            <>
         <Alert show={alertShow} variant="success">
        <Alert.Heading>{title}</Alert.Heading>
        <p>
         {message}
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={onClick} variant="success" >
            oke
          </Button>
        </div>
      </Alert>

        </>
    )
}
    if(type === "error"){
        return (
            <>
         <Alert show={alertShow} variant="danger">
        <Alert.Heading>{title}</Alert.Heading>
        <p>
         {message}
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={onClose} variant="waring" >
            oke
          </Button>
        </div>
      </Alert>

        </>
    )
}
}
export default AlertComponent;