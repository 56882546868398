import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {  useSearchParams, useNavigate} from "react-router-dom";
import { loading_image } from "../../assets";
import { APIEndpoint } from "../../utils/api-text";


const AuthPage = () =>{
    const [searchParams, setSearchParams] = useSearchParams()
    const authCode = searchParams.get("code");
    const redirect_uri = "https://dunex.happycat-happydog.id/authLoader";
    console.log(authCode);
    const navigate = useNavigate();

    //state on here
    const [accessToken, setAccessToken] = useState(null);

    const getRefreshCode = async() => {
        const {auth_zoho} = APIEndpoint();
       const Uri = auth_zoho;
       const Data = {
        "code" : authCode,
        "redirect_uri" : redirect_uri
       }
      await axios.post(Uri, Data)
      .then(res => {
        const {access_token} = JSON.parse(res.data.message[0].body);
        setAccessToken(access_token);
        console.log(res.data.message);
        localStorage.setItem('token', access_token);
        
        navigate("/dashboard", {state:{auth : access_token}, replace:true})
    })
    .catch(err => {
        console.log(err)
    })
    
}
useEffect(()=>{
    getRefreshCode();
    },[])
    
   
    

    return(
        <div style={{height:"100vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <div style={{ justifyContent:"center",alignItems:"center", backgroundColor:"white"}} >
                <div style={{position:"absolute", right:"46%"}} >
                <Spinner variant="warning" />
                </div>
                <img src={loading_image} sizes="cover" />
                <p className="fs-1" style={{textAlign:"center"}} >Mohon Tunggu<br/> Proses Authentikasi Zoho..</p>
            </div>


        </div>
    )
}

export default AuthPage;