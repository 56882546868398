import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Table,Pagination } from "react-bootstrap";
import { LoadingComponent } from "../../../components";


const ShippingList = () => {

const [listShipping, setListShipping] = useState(null);
const [isLoading, setIsLoading] = useState(false);



    const getDunexAuth = () => {
        setIsLoading(true);
        axios.get("https://api.happycat-happydog.id/Api/auth-dunex")
        .then(res => {
            
            const getData = res.data.data
            const getAuth =  JSON.parse(getData)
            console.log(getAuth)
            var myHeaders = new Headers();
myHeaders.append("Authorization", getAuth.token);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  redirect: 'follow'
};

fetch("https://api.happycat-happydog.id/Api/shipment-dunex", requestOptions)
  .then(response => response.text())
  .then(result => {
    const listShipmentData = JSON.parse(result);
    const cleanData = JSON.parse(listShipmentData.data);
    setListShipping(cleanData.data);
    setIsLoading(false);

    console.log(cleanData.data);
  })
  .catch(error => console.log('error', error));
        })
        .catch(err => console.log(err))
    }
    useEffect(()=>{
        getDunexAuth();
    },[])



    let active = 2;
    let items = [];
    for(let number = 1; number <= 10; number++){
        items.push(
            <Pagination.Item key={number} active={number === active}>
                {number}
            </Pagination.Item>
        )
    }
    if(isLoading === true){
        return <LoadingComponent/>
    }
    return(
        <div style={{padding: 20}} >
            <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>

          <div  style={{marginTop:20}}>
          <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Customer</th>
          <th>Address</th>
        </tr>
      </thead>
      <tbody>
        {listShipping === null ? "data tidak di temukan :( " : listShipping.map(item => (

        <tr>
          <td>{item.ID}</td>
          <td>{item.Customer}</td>
          <td>{item.Address}</td>
        </tr>
        ))}
       
       
      </tbody>
    </Table>
   
          </div>
            
        </div>
    )
}
export default ShippingList;