import React, { useEffect, useState } from "react";
import { Badge, Button, Form, Card, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { APIEndpoint } from "../../../utils/api-text";
import axios from "axios";
import { ProductCard } from "../../../components";



const TrackingShipmentShow = ({onCancel}) => {
    const stateGlobal = useSelector(state => state);
    const [history, setHistory] = useState(null);
    const [listProduct, setListProduct] = useState(null);
    const [trackingData, setTrackingData] = useState(null);
    useEffect(() => {
        getData();
    },[])

    const getData = () => {
        const {auth_dunex, tracking_shipment_order_dunex} = APIEndpoint();
        const orderId = stateGlobal.ship_id
        axios.get(auth_dunex)
        .then(res => {
            // console.log(res)
            const auth_token = res.data.data.token;
            axios.get(`${tracking_shipment_order_dunex}?shipId=${orderId}`, {headers : {Authorization : auth_token}})
            .then(res => {
                const getTrack = JSON.parse(res.data.data);
                console.log("ini adalah data track :", getTrack.data);
                setHistory(getTrack.data.order_data.history);
                setListProduct(getTrack.data.order_data.detail_order);
                setTrackingData(getTrack.data.order_data);
            })
            .catch(err => console.log("tracking error :", err))
        })
        .catch(err => {
            console.log("auth dunex error :", err)
        })
    }

    
    return(
        <div style={{padding: 20}} >

               <p className="fs-3" >
            <Badge bg="secondary" >
            tracking shipment
            </Badge>
            </p>
            <Form>
               
           <div style={{display:"flex", margin:10}} >

          
        </div>
        <Row>
        <Col>
        

        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
            order ID
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.order_id  }
            </Col>
        </Row>
            
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
            Customer Name
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.customer_name}
            </Col>
        </Row>
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
            No Phone
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.no_hp}
            </Col>
        </Row>
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
            SO Number
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.sales_order}
            </Col>
        </Row>
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
            Shipment Date
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.shipment_date}
            </Col>
        </Row>
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
           Shipping address
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.shipping_address}
            </Col>
        </Row>
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
           Total Quantity
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.total_qty}
            </Col>
        </Row>
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
           Total Weight
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.total_weight}
            </Col>
        </Row>
       
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
           Transporter
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.transporter}
            </Col>
        </Row>       
        <Row style={{borderBottom: "2px solid black", marginBottom:10}} >
            <Col xs lg={4} >
           Shipping Note
            </Col>
            <Col>
            : {trackingData === null ? "-" : trackingData.shipping_note === "undefined" ? "-" : trackingData.shipping_note}
            </Col>
        </Row>       
        </Col>
        <Col  >
        <Row style={{padding: 24}} >

        
        {history ? history.map((data, index) => (
            <Col>
               <Card
               bg="warning"
               key={index}
               style={{ width: '21rem' }}
               className="mb-2"
               >
          <Card.Header>{data.do_number === "" ? "do number belum ada !" : data.do_number}</Card.Header>
          <Card.Body>
            <p className="fs-6" >
             status : {data.status_do === "" ? "belum ada status do !" : data.status_do}
            </p>
            
              <p className="fs-6" >
                <Badge bg="success" > date : {data.date === "" ? "-" : data.date} </Badge>
              </p>
              <p className="fs-6" >
                <Badge bg="success" > warehouse : {data.warehose === "" ? "-" : data.warehose} </Badge>
              </p>
            
            
          </Card.Body>
        </Card>
                   </Col>
            )) : null
        }
        </Row>
        
        </Col>
        </Row>
        <p className="fs-5" >
        <Badge bg="secondary" >
            Product List
        </Badge>
        </p>
        <Row>
        { listProduct ? listProduct.map(item => (       
               <Col >
            <ProductCard 
            productName={item.product_name === "" ? "-" : item.product_name} 
            sku={item.sku === "" ? "-" : item.sku}  
            quantity={item.quantity === "" ? "-" : item.quantity} 
            expiredDate={item.expired_date === "0000-00-00" ? "-" : item.expired_date}
            warehouses={item.warehouse_allocation === "" ? "-" : item.warehouse_allocation}
            />
            </Col>
            )) : null
            }
        </Row>
      <Button variant="warning" onClick={onCancel}>
        Close
      </Button>
      
    </Form>

        </div>
    )
}
export default TrackingShipmentShow;