import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { hpi_logo } from "../../assets";


const SideBar = ({onClickHome, onClickShipping, onClickShipment, onClickSalesOrder, onClickTrackShip}) => {
   
    return (
        <div style={{
            height:"97vh", 
            backgroundColor: 
            "white", width:"25vh", 
            display:"flex",
            flexDirection:"column", 
            position:"absolute", 
            margin:10, 
            borderRadius:15}} >
            <div style={{ width:"100%", height: "15vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <Image src={hpi_logo} width={100} fluid />
            </div>
            <div style={{ display:"flex", justifyContent:"center", marginBottom:20}} >
               <Button variant="primary" size="xs" style={{width:"20vh"}} onClick={onClickHome} >Home</Button>
            </div>
            <div style={{ display:"flex", justifyContent:"center", marginBottom:20}} >
               <Button disabled variant="primary" size="xs" style={{width:"20vh"}} onClick={onClickShipment}>Manual Shipment</Button>
            </div>
            <div style={{ display:"flex", justifyContent:"center", marginBottom:20}} >
               <Button variant="primary" size="xs" style={{width:"20vh"}} onClick={onClickSalesOrder}>Sales Order Zoho</Button>
            </div>
            <div style={{ display:"flex", justifyContent:"center", marginBottom:20}} >
               <Button variant="primary" size="xs" style={{width:"20vh"}} onClick={onClickTrackShip}>Track Shipment</Button>
            </div>
        </div>

    )
}
export default SideBar;