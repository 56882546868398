import React, { useState } from "react";
import { Col, Row, Form, Button, Dropdown, Spinner, Table } from "react-bootstrap";
import { CustomerCard } from "../../../components";


const CreateSalesOrder = ({onCencel}) => {
    const [buttonLoading, setButtonLoading] = useState(false);
    return(
        <div style={{padding:20}} >
            <div>
                <Row>
                    <Col>
                    <p className="fs-3" >
                        Create New Sales Order
                    </p>
                    </Col>
                    <Col lg={1} style={{display:"flex", justifyContent:"flex-end"}}  >
                    <div onClick={onCencel} style={{cursor:"pointer"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
                </div>
                    </Col>
                </Row>
            </div>
            <Form>
        <Form.Group as={Col} controlId="formGridOrderNumber">
          <div style={{display:"flex", justifyContent:"center"}} >
            <Button variant="success" >Tambahkan Customers</Button>
          </div>
          <div style={{display:"flex", justifyContent:"center", marginTop:10, marginBottom:10}} >
          <CustomerCard/>
          </div>
        </Form.Group>

      
     
     

        <Form.Group controlId="formGridOrderNumber">
          <Form.Label>Order Number</Form.Label>
          <Form.Control type="text" placeholder="Order Number" />
        </Form.Group>
        <Form.Group  controlId="formGridReference">
          <Form.Label>Refference</Form.Label>
          <Form.Control type="text" placeholder="Reference" />
        </Form.Group>
      
      <Row className="mb-3">
        <Form.Group as={Col} controlId="formGridOrderDate">
          <Form.Label>Order Date</Form.Label>
          <Form.Control type="date" placeholder="Order Date" />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridExpectedShipmentDate">
          <Form.Label>Expected Shipment Date</Form.Label>
          <Form.Control type="date" placeholder="Expected Shipment Date" />
        </Form.Group>
      </Row>

      
      {/* DROPDOWN PAYMENT TERMS */}
      <Form.Group className="mb-3" controlId="formGridAddress1">
        {/* <Form.Label>Payment Terms</Form.Label> */}
        <div style={{display:"flex", justifyContent:"center"}} >
      <Dropdown >
        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:"75vh"}} >
          {/* {filterValue != null ? filterValue.Customer : "pilih shipping to"} */}
          Payment Terms
        </Dropdown.Toggle>
  
        <Dropdown.Menu style={{width:"75vh", padding:10}} >
        <Form className="d-flex">
            <Form.Control
              type="text"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              //   onChange={(e) => setFilterDropdown(e.target.value)}
              />
            <Button variant="outline-success"  >
                Search {""} {buttonLoading && <Spinner variant="warning"/>}
                </Button>
          </Form>
          {/* {shippingList === null ? null : shippingList.map(items => (
              
              <Dropdown.Item onClick={() => setFilterValue(items)}>{items.Customer}</Dropdown.Item>
            ))} */}
        </Dropdown.Menu>
      </Dropdown>
            </div>
      </Form.Group>
      {/* DROPDOWN Delivery Methode */}
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <div style={{display:"flex", justifyContent:"center"}} >
      <Dropdown >
        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:"75vh"}} >
          {/* {filterValue != null ? filterValue.Customer : "pilih shipping to"} */}
          Delivery Methode
        </Dropdown.Toggle>
  
        <Dropdown.Menu style={{width:"75vh", padding:10}} >
        <Form className="d-flex">
            <Form.Control
              type="text"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              //   onChange={(e) => setFilterDropdown(e.target.value)}
              />
            <Button variant="outline-success"  >
                Search {""} {buttonLoading && <Spinner variant="warning"/>}
                </Button>
          </Form>
          {/* {shippingList === null ? null : shippingList.map(items => (
              
              <Dropdown.Item onClick={() => setFilterValue(items)}>{items.Customer}</Dropdown.Item>
            ))} */}
        </Dropdown.Menu>
      </Dropdown>
            </div>
      </Form.Group>
      {/* DROPDOWN Warehouse Name */}
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <div style={{display:"flex", justifyContent:"center"}} >
      <Dropdown >
        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:"75vh"}} >
          {/* {filterValue != null ? filterValue.Customer : "pilih shipping to"} */}
          Warehouse Name
        </Dropdown.Toggle>
  
        <Dropdown.Menu style={{width:"75vh", padding:10}} >
        <Form className="d-flex">
            <Form.Control
              type="text"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              //   onChange={(e) => setFilterDropdown(e.target.value)}
              />
            <Button variant="outline-success"  >
                Search {""} {buttonLoading && <Spinner variant="warning"/>}
                </Button>
          </Form>
          {/* {shippingList === null ? null : shippingList.map(items => (
              
              <Dropdown.Item onClick={() => setFilterValue(items)}>{items.Customer}</Dropdown.Item>
            ))} */}
        </Dropdown.Menu>
      </Dropdown>
            </div>
      </Form.Group>
      {/* DROPDOWN Sales Person */}
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <div style={{display:"flex", justifyContent:"center"}} >
      <Dropdown >
        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:"75vh"}} >
          {/* {filterValue != null ? filterValue.Customer : "pilih shipping to"} */}
          Sales Person
        </Dropdown.Toggle>
  
        <Dropdown.Menu style={{width:"75vh", padding:10}} >
        <Form className="d-flex">
            <Form.Control
              type="text"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              //   onChange={(e) => setFilterDropdown(e.target.value)}
              />
            <Button variant="outline-success"  >
                Search {""} {buttonLoading && <Spinner variant="warning"/>}
                </Button>
          </Form>
          {/* {shippingList === null ? null : shippingList.map(items => (
              
              <Dropdown.Item onClick={() => setFilterValue(items)}>{items.Customer}</Dropdown.Item>
            ))} */}
        </Dropdown.Menu>
      </Dropdown>
            </div>
      </Form.Group>
      {/* DROPDOWN Channel */}
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <div style={{display:"flex", justifyContent:"center"}} >
      <Dropdown >
        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{width:"75vh"}} >
          {/* {filterValue != null ? filterValue.Customer : "pilih shipping to"} */}
          Channel
        </Dropdown.Toggle>
  
        <Dropdown.Menu style={{width:"75vh", padding:10}} >
        <Form className="d-flex">
            <Form.Control
              type="text"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              //   onChange={(e) => setFilterDropdown(e.target.value)}
              />
            <Button variant="outline-success"  >
                Search {""} {buttonLoading && <Spinner variant="warning"/>}
                </Button>
          </Form>
          {/* {shippingList === null ? null : shippingList.map(items => (
              
              <Dropdown.Item onClick={() => setFilterValue(items)}>{items.Customer}</Dropdown.Item>
            ))} */}
        </Dropdown.Menu>
      </Dropdown>
            </div>
      </Form.Group>
     
            <Form.Group className="mb-3" controlId="formGridAddress1">
                <div style={{display:"flex", flexDirection:"column", maxWidth:300, marginBottom:25}} >
            <Form.Label>Line Items</Form.Label>
            <Button variant="success" > Tambahkan Items </Button>
                </div>
            <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Item Details</th>
          <th>Weight</th>
          <th>Exp Date</th>
          <th>Quantity</th>
          <th>Rate</th>
          <th>Discount</th>
          <th>Tax</th>
          <th>Ammount</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
          <td>@fat</td>
          <td>@fat</td>
          <td>@fat</td>
          <td>@fat</td>
          <td>@fat</td>
          <td>@fat</td>
        </tr>
       
      </tbody>
    </Table>
            </Form.Group>

      

      

      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>

        </div>
    )
}
export default CreateSalesOrder;